<template>
  <!--end::Theme mode setup on page load-->
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root" id="kt_app_root">
    <div id="global-messages" v-if="errors">
      <BaseAlert
        v-if="errors"
        :content="errors"
        type="error"
        @dismiss-alert="clearGlobalErrors"
      />
    </div>
    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
      <!--end::Logo-->
      <!--begin::Aside-->
      <div class="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
        <!--begin::Wrapper-->
        <div class="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
          <!--begin::Header-->
          <div class="d-flex flex-stack py-2">
            <!--begin::Back link-->
            <div class="me-2"></div>
            <!--end::Back link-->
            <!--end::Sign Up link=-->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="py-20">
            <!--begin::Form-->
            <form
              class="form w-100"
              id="kt_sign_in_form"
              @submit.prevent="submit"
            >
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Heading-->
                <div class="text-start mb-10">
                  <!--begin::Title-->
                  <h1 class="text-dark mb-3 fs-3x" data-kt-translate="sign-in-title">Faça seu login</h1>
                  <!--end::Title-->
                </div>
                <!--begin::Heading-->
                <!--begin::Input group=-->
                <div class="fv-row mb-8">
                  <!--begin::Email-->
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    autocomplete="off"
                    data-kt-translate="sign-in-input-email"
                    class="form-control form-control-solid"
                    v-model.trim="form.email"
                  />
                  <!--end::Email-->
                </div>
                <!--end::Input group=-->
                <div class="fv-row mb-7">
                  <!--begin::Password-->
                  <input
                    type="password"
                    placeholder="Senha"
                    name="password"
                    autocomplete="off"
                    data-kt-translate="sign-in-input-password"
                    class="form-control form-control-solid"
                    v-model.trim="form.password"
                  />
                  <!--end::Password-->
                </div>
                <!--end::Input group=-->
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10">
                  <div></div>
                  <!--begin::Link-->
                  <a href="#" class="link-primary" data-kt-translate="sign-in-forgot-password">Esqueci a senha</a>
                  <!--end::Link-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Actions-->
                <div class="d-flex flex-stack">
                  <!--begin::Submit-->
                  <Button
                    type="submit"
                    class="btn-primary"
                    :loading="loader"
                  >
                    Entrar
                  </Button>
                  <!--end::Submit-->
                </div>
                <!--end::Actions-->
              </div>
              <!--begin::Body-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Body-->
          <!--begin::Footer-->
          <div class="m-0">
          </div>
          <!--end::Footer-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Aside-->
      <!--begin::Body-->
      <div class="d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat" style="background-image: url(assets/media/auth/bg-pixel-roads.png)"></div>
      <!--begin::Body-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import { mapActions, mapState } from 'vuex'

import BaseAlert from '@/components/common/Alert/BaseAlert'
import Button from '@/components/common/Button/Button'

export default {
  components: {
    BaseAlert,
    Button
  },
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      loader: false
    }
  },
  metaInfo () {
    return {
      title: 'Login'
    }
  },
  computed: {
    ...mapState({
      errors: state => state.errors
    })
  },
  methods: {
    ...mapActions(['clearErrors']),
    clearGlobalErrors () {
      this.clearErrors()
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        const response = await AuthService.login(this.form)
        if (response.use_2fa) {
        } else {
          location.reload()
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>

#kt_app_root {
  background: #fff;
  min-height: 100vh;
}

#global-messages {
  position: fixed;
  top: 120px;
  left: calc(50% - 140px);
  width: 280px;
  z-index: 10;

  @include tablet {
    width: 500px;
    left: calc(50% - 250px);
  }
}

</style>
